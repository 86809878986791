import React, { useState, useCallback, useRef } from 'react';
import RegistrationForm from './RegistrationForm.js';
import FileUpload, { uploadFileInChunks } from '../../components/FileUpload.js';
import { useLoading } from '../../components/LoadingProvider.js';
import { ALLOWED_MODEL_FILE_EXTENSIONS, ONE_HUNDRED_MB_IN_BYTES } from '../../shared/Constants.js';
import Accordion from 'react-bootstrap/Accordion';
import HorizontalScroll from '../../components/HorizontalScroll.js';
import RollingOverflowText from '../../components/RollingOverflowText.js';

const DEMO_MODELS = [
    { Title: "Demo Factory", Key: "demo_factory", ThumbnailExtension: "jpg" },
    { Title: "Demo Office", Key: "demo_office", ThumbnailExtension: "jpg" },
    { Title: "Demo Hospital", Key: "demo_hospital", ThumbnailExtension: "jpg" },
    { Title: "Demo City", Key: "demo_city", ThumbnailExtension: "jpg" },
    { Title: "Demo Airport", Key: "demo_airport", ThumbnailExtension: "jpeg" },
    { Title: "Demo Stadium", Key: "demo_stadium", ThumbnailExtension: "jpeg" },
    { Title: "Demo Construction", Key: "demo_construction", ThumbnailExtension: "jpeg" }
];

const DemoModelCard = ({selectedDemoModel, demoModel, selectDemoModelCard}) => {
    const cardRef = useRef();
    return (                                    
    <div className={`card${selectedDemoModel === demoModel ? " selected" : ""}`} onClick={() => {selectDemoModelCard(demoModel);}} id={demoModel.Title.replace(/\s/g, '') + "Model"} ref={cardRef}>
        <img src={`/${demoModel.Key}.${demoModel.ThumbnailExtension}`} className="card-img-top" alt={demoModel.Title}/>
        <div className="card-body">
            <h6 className="card-title"><RollingOverflowText text={demoModel.Title} parentRef={cardRef}></RollingOverflowText></h6>
        </div>
    </div>
    );
}

const RegistrationModelUploadForm = ({ onNext, onCancel, showPrevious, showNext, showSkip, nextButtonText }) => {
    const { loading, setLoading, setTextHeader, setTextMessage, progress, setProgress } = useLoading();
    const [file, setFile] = useState(null);
    const [isInputValid, setIsInputValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const inputFileElementRef = useRef();
    const [demoModel, setDemoModel] = useState("");
    const horizontalMenuRef = useRef();

    const handleFileChange = useCallback((event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const maxFileSize = ONE_HUNDRED_MB_IN_BYTES;

            const fileName = selectedFile.name;
            const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

            if (selectedFile.size <= maxFileSize && ALLOWED_MODEL_FILE_EXTENSIONS.includes(fileExtension)) {
                setFile(selectedFile);
                setIsInputValid(true);
                setErrorMessage("");
            } else {
                setFile(null);
                setIsInputValid(false);
                if (!ALLOWED_MODEL_FILE_EXTENSIONS.includes(fileExtension)) {
                    setErrorMessage("The selected file format is not allowed.");
                } else if (selectedFile.size > maxFileSize) {
                    setErrorMessage("The selected file is larger than the allowed limit.");
                }
            }

            setDemoModel(null);
        } else {
            setFile(null);
            setIsInputValid(false);
            setErrorMessage("");
        }
    }, []);

    const handleNext = () => {
        if (isInputValid) {
            setTextHeader("Uploading model...")
            setTextMessage("Preparing your model. Please wait...");
            setProgress(0);
            setLoading(true);
            if (file) {
                uploadFileInChunks(file, (fileUploadProgress) => {
                    setProgress(fileUploadProgress);
        
                    if (fileUploadProgress === 100) {
                        setTextHeader("Processing model...")
                        setTextMessage("Almost done. Please wait...");
                        setProgress(null);
                    }   
                }, () => {
                    // All chunks uploaded, notify success
                    setProgress(null);
                    setLoading(false);

                    // Move to the next step of onboarding
                    onNext();
                });
            } else {
                const modelJobGuid = crypto.randomUUID();
                fetch(`/Registration/model/${modelJobGuid}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(demoModel.Key),
                  })
                    .then((response) => {
                      if (response.ok) {
                        onNext();
                      } else {
                        setErrorMessage("Setting demo model failed.");
                      }
                    })
                    .catch((error) => {
                      setErrorMessage("Something went wrong.");
                      console.error(error);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
            }
        }
    };

    const selectDemoModel = (newDemoModel) => {
        if (newDemoModel === demoModel) { // if click on already selected demo model, deselect
            setFile(null);
            setIsInputValid(false);
            setErrorMessage("");
            setDemoModel(null);

            inputFileElementRef.current.setTitle(null);
        } else {
            setDemoModel(newDemoModel); 
            setIsInputValid(true);

            inputFileElementRef.current.setTitle(newDemoModel.Title);
        }
    }

    return (
        <RegistrationForm
            onNext={handleNext}
            onSkip={onNext}
            onCancel={onCancel}
            isInputValid={isInputValid}
            showPrevious={showPrevious}
            showNext={showNext}
            showSkip={showSkip}
            nextButtonText={nextButtonText}
        >
            <div className="registrationModelUploadForm">
                <h4>Upload the model</h4>
                <div className="dialogContent">
                    <p>
                        Let's add your 3D model to your digital twin! Please upload your 3D model in either .fbx or .obj format, with a maximum file size of 100 MB. This will allow us to create an accurate representation of your physical assets in the digital world.
                    </p>
                </div>

                <div className='p-3'>
                    <p>Model File <span className="infoText">(.FBX, .OBJ)</span></p>
                    <FileUpload handleFileChange={handleFileChange} ref={inputFileElementRef} className="mt-2" title="My Selected Twin.obj"/>
                    {loading && (
                    <div>
                        <p>Upload Progress: {progress}%</p>
                        {/* You can customize the progress bar or any other indicator here */}
                    </div>
                    )}
                {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
                </div>

                <div className='uploadModelDropdownSection'>
                    <h4>Don't have a 3D model?</h4>
                    <div>
                    <Accordion flush alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header id="DropdownDemoModels">{"Try some of our Demo models:"}</Accordion.Header>
                            <Accordion.Body>
                                <HorizontalScroll horizontalScrollContainerRef={horizontalMenuRef}>
                                    <div className='horizontalMenu horizontalScroll' ref={horizontalMenuRef}>  
                                        {DEMO_MODELS.map(model => {
                                            return (
                                                <DemoModelCard selectedDemoModel={demoModel} demoModel={model} selectDemoModelCard={selectDemoModel} key={model.Key}/>
                                            )
                                        })}
                                    </div>
                                </HorizontalScroll>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header id="DropdownSuggestions">{"Here are some suggestions to help you get started:"}</Accordion.Header>
                            <Accordion.Body>
                            <div className="further-info-field">
                                <ul className="links-list">
                                    <li><a href="https://www.twinzo.eu/blog/blog-1/tutorial-scan-your-model-with-iphone-59" target="_blank" rel="noreferrer" id="RedirectLink1">Scan a physical object with a 3D scanning app on your smartphone and export it in .fbx or .obj format.</a></li>
                                    <li><a href="https://www.twinzo.eu/blog/blog-1/navigating-the-world-of-3d-modeling-55" target="_blank" rel="noreferrer" id="RedirectLink2">Create a model using your preferred 3D modeling solution and export it in .fbx or .obj format.</a></li>
                                    <li><a href="https://www.visualcomponents.com/" target="_blank" rel="noreferrer" id="RedirectLink3">Use Visual Components to build and export your model.</a></li>
                                    <li><a href="https://www.twinzo.eu/partners" target="_blank" rel="noreferrer" id="RedirectLink4">Hire a freelance 3D modeler to create a model for you. Check our partners.</a></li>
                                    <li><a href="https://twinzo.atlassian.net/wiki/spaces/PUBD/pages/109740040" target="_blank" rel="noreferrer" id="RedirectLink5">Purchase a pre-made model from an online 3D model marketplace.</a></li>
                                    <li><a href="https://www.twinzo.eu/blog/blog-1/how-to-scan-your-facility-54" target="_blank" rel="noreferrer" id="RedirectLink6">Create a 3D model from photographs using photogrammetry software.</a></li>
                                </ul>
                            </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    </div>
                </div>
            </div>
        </RegistrationForm>
    );
};

export default RegistrationModelUploadForm;