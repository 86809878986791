import { ONE_MB_IN_BYTES, ALLOWED_MODEL_FILE_EXTENSIONS } from "../shared/Constants";
import { forwardRef, useState, useImperativeHandle  } from "react";

const FileUpload = forwardRef(({ handleFileChange, title = "No file selected", className = "" }, ref) => {
  const [file, setFile] = useState(() => {
    const myFile = new File([], title); // The only way to set the textBox value of input type file
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(myFile);

    return dataTransfer.files;
  });

  useImperativeHandle(ref, () => ({
    setTitle: (fileName) => {
      let file = [];
      if (fileName) {
        const myFile = new File([], fileName);
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(myFile);

        file = dataTransfer.files;
      }
      
      setFile(file);
    }
  }));

  const acceptExtensions = ALLOWED_MODEL_FILE_EXTENSIONS.map(extension => `.${extension}`).join(', ');

  return (
    <label className={"fileInput" + " " + className}>
      <input type="file" id="fromFile" accept={acceptExtensions} className={"d-none"}
      onChange={(e) => { setFile(e.target.files); handleFileChange(e); }} files={file}
      ref={ref}/>
      <label className="label-leading-trim">{file.length ? file[0].name : title}</label>
      <button className="btn btn-primary">...</button>
    </label>
    )
});


/**
 * Slices a file into smaller chunks and uploads it.
 * @param {File} file - The file to be uploaded.
 * @param {function(number) : void} onChunkUploaded - Called after each chunk gets uploaded. Can be used for progress tracking.
 * @param {function() : void} onFileUploaded - Called after all the chunks were sucessfuly uploaded.
 * @returns {void}
 */
export const uploadFileInChunks = (file, onChunkUploaded, onFileUploaded) => {
  let progress = 0;

  const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
  const modelJobGuid = crypto.randomUUID();
  const temporaryFileGuid = crypto.randomUUID();
  
  const chunkSize = ONE_MB_IN_BYTES;
  const chunks = Math.ceil(file.size / chunkSize);
  let currentChunk = 0;
  const uploadFileChunk = (start, end) => {
      const blob = file.slice(start, end);

      const formData = new FormData();
      formData.append("chunkMetadata", JSON.stringify({ FileGuid: temporaryFileGuid, Index: currentChunk, TotalCount: chunks }));
      formData.append('file', blob, file.name);
      fetch(`/Registration/model/${modelJobGuid}/${fileExtension}`, {
          method: 'POST',
          body: formData,
      })
      .then((response) => {
          if (response.ok) {
              currentChunk++;

              if (currentChunk < chunks) {
                  progress = Math.round((currentChunk / chunks) * 100);
                  if (onChunkUploaded)
                    onChunkUploaded(progress);
                  // Continue with the next chunk
                  const nextStart = end;
                  const nextEnd = Math.min(end + chunkSize, file.size);
                  uploadFileChunk(nextStart, nextEnd);
              } else {
                  if (onFileUploaded)
                    onFileUploaded();
              }
          } else {
              console.error('Failed to upload file:', response.statusText);
          }
      })
      .catch((error) => {
          console.error('Error uploading file:', error);
      });
  };
  const initialChunkSize = Math.min(chunkSize, file.size);
  uploadFileChunk(0, initialChunkSize);
};

export default FileUpload;