import React, { useState, useEffect, useRef, useMemo } from "react";
import Select from 'react-select'
import { getCountries, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input'
import { AsYouType } from 'libphonenumber-js'
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';
import flags from 'react-phone-number-input/flags'
import en from 'react-phone-number-input/locale/en'
import { ReactSelectDropdownIndicator } from "../shared/Constants";

const selectStyles = {
    control: (base, { isFocused }) => ({
        ...base,
        cursor: 'text',
        backgroundColor: '#101010',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        border: 'none',
        boxShadow: "none",
        display: "flex",
        flexDirection: "row-reverse",
        paddingTop: "2px",
        paddingBottom: "2px",
        marginRight: "calc(1rem + 10px)",
        marginLeft: "1rem",
        borderBottom: "1px solid #7f7f7f",
        ":hover": {
            borderBottom: "1px solid #7f7f7f",
        }
    }),
    container: (base) => ({
        ...base,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: "#101010",
        width: "calc(100% - 58px)",
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        color: '#ffffff',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#282828!important' : isFocused ? '#282828!important' : '#101010!important',
        paddingLeft: "1rem",
        paddingRight: "1rem"
    }),
    menu: (base) => ({
        ...base,
        zIndex: 3,
        margin: 0,
        backgroundColor: "#101010",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        overflow: "hidden",
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        marginRight: "10px"
    }),
    input: (base) => ({
        ...base,
        color: "white"
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 0
    })
};

const DropdownIndicator = () => ( // search icon used as dropdown indicator
    <div style={{ color: "#ffffff", height: 24, width: 32 }}>
        <svg 
            width="20"
            height="18"
            viewBox="0 0 24 24"
            focusable="false"
            role="presentation"
            style={{verticalAlign: "sub"}}
            >
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    </div>
);

function formatOptionLabel({value, label, code}) {
    const Flag = flags[value];
    return (
        <div style={{ display: "flex", alignItems:"center" }}>
            <Flag width="25px" className="countryFlagIcon" style={{minWidth: "25px", marginTop: "1px"}}/>&nbsp;&nbsp;
            <span>{label}</span>
            <span>
                &nbsp;{`(${code})`}
            </span>
        </div>
    );
}

function TwinzoPhoneNumberSelect({id="", required, value="", setValue, className="", isPhoneNumberValid, setIsPhoneNumberValid, defaultCountry}) {
    const inputRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const selectCountryOptions = useMemo(() => getCountries().map(countryCode => {
        const countryName = en[countryCode];
        const callingCode = '+' + getCountryCallingCode(countryCode);
        
        return {
            value: countryCode,
            label: countryName,
            code: callingCode
        };
    }), []);
    let [selectedCountry, setSelectedCountry] = useState(selectCountryOptions.find(c => c.value === defaultCountry));
    const [isInputFocused, setIsInputFocused] = useState(false);
    const formControlRef = useRef(null);

    const [inputValue, setInputValue] = useState("");
    
    useEffect(() => {
        setValue(selectedCountry?.code + inputValue.replace(/\s/g, ""));
    }, [inputValue]);

    const dropDownRef = useRef(null);
    // Catch if user clicks outside, if so, close select
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    function formatAndValidatePhoneNumber(number, selectedCountry) {
        let parsedNumber = parsePhoneNumber(number, selectedCountry ? undefined : defaultCountry);
        let numberToDisplay;
        if (parsedNumber && !parsedNumber.country) { // US and other countries have +1, no idea what dountry for now
            numberToDisplay = new AsYouType().input(parsedNumber.formatInternational());
            setInputValue(numberToDisplay);
        } else {
            if (parsedNumber) {
                const countryToSelect = selectCountryOptions.find((c) => c.value === parsedNumber.country);
                setSelectedCountry(countryToSelect);
                selectedCountry = countryToSelect; // Need this value to be updated after if ends to work with new values
                const formattedNumber = number.includes("+") ? number.substring(number.indexOf(countryToSelect.code) + countryToSelect.code.length) : "";
                setInputValue(formattedNumber);
                setIsPhoneNumberValid(isValidPhoneNumber(countryToSelect?.code + formattedNumber));
                number = formattedNumber; // Need this value to be updated after if ends to work with new values
            }

            if (selectedCountry)
                parsedNumber = parsePhoneNumber(selectedCountry.code + number);
            let formattedNumber = parsedNumber ? new AsYouType().input(parsedNumber.formatInternational()) : number;
            if (parsedNumber)
                formattedNumber = formattedNumber.substring(formattedNumber.indexOf(selectedCountry.code) + selectedCountry.code.length + 1)
            numberToDisplay = formattedNumber;
            setInputValue(numberToDisplay);
            setIsPhoneNumberValid(isValidPhoneNumber(selectedCountry?.code + formattedNumber));
        }

        return numberToDisplay;
    };
    
    const Flag = selectedCountry ? flags[selectedCountry.value] : undefined;
    return (
        <div className={className + " form-floating phoneNumberInputWrappper" + ((!isPhoneNumberValid && isInputFocused) ? " invalid-input" : "")} ref={dropDownRef}
        onClick={(event) =>{ if (event.target === dropDownRef.current || event.target === formControlRef.current) inputRef.current.focus();}}>
            <div className={"form-control"} ref={formControlRef}>
            <span className="phoneNumberCountryCodeIndicator">{Flag && (<><Flag width="25px" className="countryFlagIcon" style={{marginBottom: "1px"}}/>&nbsp;</>)} {selectedCountry ? (
                    <>
                        {selectedCountry.code}&nbsp;
                        <span className="textSeparator"></span>&nbsp;
                    </>
                ) : null}
            </span>
            <input type="tel" placeholder="" id={id} required={required} value={inputValue} onChange={(event) => {
                let caretStart = event.target.selectionStart;
                let caretEnd = event.target.selectionEnd;

                let number = event.target.value;
                const previousDisplayedNumber = inputValue;

                const numberToDisplay = formatAndValidatePhoneNumber(number, selectedCountry);
              
                setTimeout(() => { // This is to persist text cursor position, because setInputValue(formattedNumber); would set it to the end
                    if (numberToDisplay.length > number.length) {
                        caretStart += 1;
                        caretEnd += 1;
                    }

                    event.target.setSelectionRange(caretStart, caretEnd);

                    if (numberToDisplay.includes(" ") && !previousDisplayedNumber.includes(" ")) {
                        event.target.setSelectionRange(10000, 10000);
                    }
                });
            }} onKeyDown={(event) => {
                if (event.key === "+" && (selectedCountry || inputValue.includes("+")))
                    event.preventDefault();
                if (event.key === "Backspace" && !inputValue && selectedCountry) {
                    event.preventDefault();
                    setInputValue(selectedCountry.code);
                    setSelectedCountry(null);
                } //else if (event.key === "Backspace" && inputValue === "+" && !selectedCountry)
                    //event.preventDefault();
            }} ref={inputRef} size={16} onFocus={() => setIsInputFocused(true)} onBlur={() => setIsInputFocused(false)} maxLength={16}/>
            </div>
            <label htmlFor={id}>Your phone number</label>

            <button type="button" className="btn btn-primary selectorIndicator" onClick={() => {setIsOpen((prev) => !prev)}} title="Open Selector"><ReactSelectDropdownIndicator/></button>
            {isOpen && <Select
                className="z-3"
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                formatOptionLabel={formatOptionLabel}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={(newValue) => {
                    setSelectedCountry(newValue);
                    setIsOpen(false);
                    if (inputValue.includes("+")) {
                        setInputValue("");
                    } else {
                        formatAndValidatePhoneNumber(inputValue, newValue);
                    }
                    inputRef.current.focus();
                }}
                options={selectCountryOptions}
                placeholder="Search for Country"
                styles={selectStyles}
                tabSelectsValue={false}
                value={selectedCountry}
            />}
        </div>
    )
};

export default TwinzoPhoneNumberSelect;
