import React, { useState, useEffect, useMemo, useRef } from "react";
import RegistrationForm from "./RegistrationForm.js";
import { useLoading } from "../../components/LoadingProvider.js";
import ReCAPTCHA from "react-google-recaptcha";
import countryList from 'react-select-country-list'
import TwinzoPhoneNumberSelect from "../../components/TwinzoPhoneNumberSelect.js";
import { isEmail } from 'react-multi-email';
import TwinzoCountrySelect from "../../components/TwinzoCountrySelect.js";

const RegistrationEmailValidation = ({ onNext, onCancel, showCancel, showPrevious, showNext, showSkip, nextButtonText, defaultCountry }) => {
  const { setLoading, setTextHeader, setTextMessage, setProgress } = useLoading();
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const emailElementRef = useRef();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const countryOptions = useMemo(() => countryList().getData(), [])
  const [country, setCountry] = useState(countryOptions.find(c => c.value === defaultCountry));

  // This is used to set REcaptcha verification to verified for integration test purposes
  useEffect(() => {
    // This [true] value should be changed depending on the set config from the file .env
    // If the value is [true], the capthca will not be shown on the page and the variable isCaptchaVerified will be set to true
    // If the value is [false], the capthca will be shown on the page and the variable isCaptchaVerified will be set to false until we click the checkbox
    setIsCaptchaVerified(process.env.REACT_APP_ENV.trim() !== "Release");
  }, []);

  useEffect(() => {
    const emailValidity = isEmail(email);
    setIsEmailValid(emailValidity);
    setIsFormValid(name && isPhoneNumberValid && emailValidity && companyName && country);

    setErrorMessage(null);
  }, [email, name, phoneNumber, companyName, country]);

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(!!value);
  };

  const handleNext = async (e) => {
    // set loading text
    setTextHeader("Sending you a verification email...");
    setTextMessage("twinzo is working...");

    setLoading(true);
    setProgress(0);

    try {
      const response = await fetch(`/Registration/client/emailValidation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          phoneNumber: phoneNumber,
          emailAddress: email,
          companyName: companyName,
          country: country.value,
        }),
      });

      if (response.ok) {
        setErrorMessage(null);
        onNext();
      } else {
        setErrorMessage("Something went wrong.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderReCAPTCHA = () => {
    if (isCaptchaVerified === false) {
      return (
        <div className="catpcha mt-3 w-100">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY.trim()}
            stoken={process.env.REACT_APP_RECAPTCHA_SECURE_TOKEN.trim()}
            onChange={handleCaptchaChange}
            theme="dark"
            size="normal"
          />
        </div>
      );
    } else {
      return null; // Or you can return an alternative component or message
    }
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      isInputValid={isFormValid && isCaptchaVerified}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Start your registration process</h4>
        <div className="dialogContent">
          <p>
            We'll send a confirmation link to ensure your email is valid and ready for hassle-free onboarding.
          </p>
          <div className="form-group">
            {/* Client Login name input */}
            <div className="form-floating">
              <input type="text" className={!name ? "form-control invalid-input" : "form-control"} placeholder="" id="EmailValidationName"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}/>
              <label htmlFor="EmailValidationName">Your name</label>
            </div>
            <TwinzoPhoneNumberSelect id="EmailValidationNumber" required={true} value={phoneNumber} setValue={setPhoneNumber} className={"mt-3"}
            isPhoneNumberValid={isPhoneNumberValid} setIsPhoneNumberValid={setIsPhoneNumberValid} defaultCountry={defaultCountry}/>
            <div className="form-floating mt-3">
              <input type="email" className={!isEmailValid ? "form-control invalid-input" : "form-control"} placeholder="" id="EmailValidationEmail"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              ref={emailElementRef}/>
              <label htmlFor="EmailValidationEmail">Your email address</label>
            </div>
            <div className="form-floating mt-3">
              <input type="text" className={!companyName ? "form-control invalid-input" : "form-control"} placeholder="" id="EmailValidationCompany"
              required
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}/>
              <label htmlFor="EmailValidationCompany">Company name</label>
            </div>
            <TwinzoCountrySelect id="EmailValidationCountry" className={"form-floating w-100 mt-3"} required={true} label="Country"
             value={country} setValue={setCountry} menuPlacement="top" />
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
          {renderReCAPTCHA()}
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationEmailValidation;
