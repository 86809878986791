import { components } from 'react-select'

/**
 * An array of extensions that backend accepts, without dots. Example: ["fbx", "obj"]
 * @type {string[]}
 */
export const ALLOWED_MODEL_FILE_EXTENSIONS = ["fbx", "obj"];
export const ONE_MB_IN_BYTES = 1024 * 1024;
export const ONE_HUNDRED_MB_IN_BYTES = 100 * ONE_MB_IN_BYTES;



export const reactSelectStyles = {
    control: (base, { isFocused, hasValue }) => ({
        ...base,
        cursor: 'pointer',
        backgroundColor: (isFocused && !hasValue) ? "#330506" : '#101010',
        borderRadius: '10px',
        border: 'none',
        //outline: (isFocused && !hasValue) ? "3px solid #ff5055" : "transparent",
        "::before": (isFocused && !hasValue) ? { // Rather then using outline, use this so that the dropdown can be rendered below it
            content: '""', /* Required for pseudo-elements */
            position: "absolute", /* Position it absolutely within the parent */
            top: "-2px", /* Adjust based on the thickness of the outline */
            left: "-2px", /* Adjust based on the thickness of the outline */
            right: "-2px", /* Adjust based on the thickness of the outline */
            bottom: "-2px", /* Adjust based on the thickness of the outline */
            border: "3px solid #ff5055", /* Outline color and thickness */
            borderRadius: "10px",
            pointerEvents: "none", /* Ensures the pseudo-element does not interfere with user interactions */
            boxSizing: "border-box", /* Ensure the sizing includes the border */
            zIndex: 4
        } : {},
        boxShadow: "none",
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        color: '#ffffff',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#282828!important' : isFocused ? '#282828!important' : '#101010!important',
    }),
    singleValue: (base) => ({
        ...base,
        color: '#ffffff',
        padding: "0",
        margin: "0",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
    }),
    indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: 'none',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: '#ffffff',
        backgroundColor: '#6480fc',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        ':hover': {
            backgroundColor: '#8097ff',
        },
        ':active': {
            backgroundColor: '#475fcc',
        },
        boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.4)",
        height: "58px",
        width: "58px",
        alignItems: "center",
        justifyContent: "center",
        "svg": {
            width: "30px",
            height: "30px",
            filter: "drop-shadow(0 1px 1px #00000066)"
        },
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 2,
    }),
    menu: (base) => ({
        ...base,
        zIndex: 3,
        margin: 0,
        backgroundColor: "#101010",
        borderRadius: "10px",
        overflow: "hidden",
        width: "calc(100% - 58px)"
    }),
    menuList: (base) => ({
        ...base,
        padding: 0,
        marginRight: "10px",
    }),
    valueContainer: (base, {isFocused, hasValue}) =>({ // this is to move it down somewhat, for floating label
        ...base,
        padding: "1rem 0.75rem",
        lineHeight: "1.25",
        maxHeight: "calc(3.5rem + calc(var(--bs-border-width) * 2))",
        paddingTop: hasValue ? "1.625rem" : "",
        paddingBottom: hasValue ? "0.625rem" : "",
        opacity: (isFocused || hasValue) ? "1" : "0"
    }),
    input: (base) =>({
        ...base,
        padding: "0",
        margin: "0",
        color: "white"
    }),
    placeholder: (base) => ({
        ...base,
        margin: "0"
    }),
};

export const reactSelectLabel = (forElementId, label) => {
    return (props) => {
      const style = { // these are used in addition to bootstrap's
        transform: props.hasValue ? "scale(0.85) translateY(-0.5rem) translateX(0.1rem)" : "",
        color: props.hasValue ? "#AAAAAA" : "",
      };
  
      return (
        <>
          <label htmlFor={forElementId} style={style}>{label}</label>
          <components.Control {...props} />
        </>
      );
    };
};

export const ReactSelectDropdownIndicator = () => {
    return (
        <svg height="30" width="30" viewBox="0 0 20 20" aria-hidden="true" focusable="false" style={{
            display: 'inline-block',
            fill: 'currentColor',
            lineHeight: 1,
            stroke: 'currentColor',
            strokeWidth: 0,
            filter: "drop-shadow(0 1px 1px #00000066)"
          }}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
    );
}