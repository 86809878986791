import { useEffect, useRef } from "react";

/**
 * A Text which rolls on mouse hover if it overflows inside its container.
 * @param {string} text - The text to display.
 * @param {React.ref} parentRef - Ref to the element on which to register onMouseEnter events that trigger roll.
 * @returns {React.Component}
 */
function RollingOverflowText({text, parentRef}) {
    const wrapperRef = useRef();
    useEffect(() => {
        const roll = () => {
            if (wrapperRef.current.clientWidth > wrapperRef.current.parentNode.clientWidth) 
                wrapperRef.current.style.transform = `translate(${wrapperRef.current.parentNode.clientWidth - wrapperRef.current.clientWidth}px, 0)`;
        };
        const rollBack = () => {
            if (wrapperRef.current.clientWidth > wrapperRef.current.parentNode.clientWidth) 
            wrapperRef.current.style.transform = "translate(0, 0)";
        };

        parentRef.current.addEventListener('mouseenter', roll);
        parentRef.current.addEventListener('mouseleave', rollBack);
    }, []);

    return (
        <div className="rollingTextWrapper" ref={wrapperRef}>
            {text}
        </div>
    );
}

export default RollingOverflowText;