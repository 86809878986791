import React from "react";
import RegistrationForm from "./RegistrationForm.js";

const RegistrationEmailEndOfFlow = ({ onNext, onCancel, onSkip, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {

  return (
    <RegistrationForm
      onNext={onNext}
      onSkip={onSkip}
      onCancel={onCancel}
      isInputValid={true}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Email sent!</h4>
        <div className="dialogContent">
          <p>
          Check your inbox for a registration link we've just sent you. To complete your registration, please click on the link in the email. If you wish to close this website,
           you can do so now. Note that the registration link will be valid for 24 hours for your convenience.
          </p>
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationEmailEndOfFlow;
