import { React, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { DialogLayout } from "./components/layouts/DialogLayout.js";
import { LoadingProvider } from "./components/LoadingProvider.js";
import RegistrationClientDialog from "./views/registration/RegistrationDialog.js";
import CookieConsent from "./components/CookieConsentPopup.js";

const App = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(true);

  return (
    <LoadingProvider>
      <Routes>
        <Route path="/" element={
            <DialogLayout>
              <RegistrationClientDialog />
            </DialogLayout>
            }>
        </Route>
      </Routes>
      {showCookieConsent && (
        <CookieConsent onAccept={() => setShowCookieConsent(false)} />
      )}
    </LoadingProvider>
  );
};

export default App;
