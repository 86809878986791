import React, { useState, useEffect } from "react";
import RegistrationForm from "./RegistrationForm.js";
import { useLoading } from "../../components/LoadingProvider.js";

const RegistrationClientForm = ({ onNext, onCancel, clientName, setClientName, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {
  const { setLoading, setTextHeader, setTextMessage, setProgress } = useLoading();
  const [errorMessage, setErrorMessage] = useState("");
  const [isInputValid, setIsInputValid] = useState(false);

  useEffect(() => {
    setIsInputValid(validateInput(clientName));
    setErrorMessage("");
  }, [clientName]);

  const handleNext = async (e) => {
    // e.preventDefault();

    // check if input is filled corectly
    if (!isInputValid) {
      setErrorMessage(
        "Please fill in all the required fields."
      );
      return;
    }

    // set loading text
    setTextHeader("Checking if company/project exists...");
    setTextMessage("twinzo is looking...");

    setLoading(true);
    setProgress(0);
    // check if client already exists via Registration controller
    fetch(`/Registration/client/exists/${clientName}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // Client already exists with the given login
          setIsInputValid(false);
          setErrorMessage(
            "This name is already used, please choose a different one"
          );
        } else {
          // Client name is unique and valid, so we can proceed to the next form step
          setErrorMessage(null);
          onNext();
        }
      })
      .catch((error) => {
        setErrorMessage("Something went wrong.");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // validate input value with regex
  // only letters and numbers are allowed
  // min length is 3, max length is 50
  const validateInput = (inputValue) => {
    const isValid = /^[a-zA-Z0-9]{3,50}$/.test(inputValue);
    return isValid;
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      isInputValid={isInputValid}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Type in the name of your company/project or other</h4>
        <div className="dialogContent">
          <p>
            To begin, please provide a name for your account, organization,
            project, group, or domain. The name should be no longer than 50
            characters and cannot include any special characters.
          </p>
          <div className="form-group">
            {/* Client Login name input */}
            <div className="form-floating mt-3">
              <input
                id="name-input"
                className={!isInputValid ? "form-control invalid-input" : "form-control"}
                type="text"
                required
                placeholder=""
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
              <label htmlFor="name-input">Name of the company/project...</label>
            </div>
          </div>
        </div>
        {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
      </>
    </RegistrationForm>
  );
};

export default RegistrationClientForm;
