import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams } from 'react-router-dom'
import { useLoading } from "../../components/LoadingProvider.js";
import RegistrationBranchForm from "./RegistrationBranch";
import RegistrationClientForm from "./RegistrationClient";
import RegistrationUserForm from "./RegistrationUser";
import RegistrationModelUploadForm from "./RegistrationModelUpload";
import RegistrationFinalScreenForm from "./RegistrationFinalScreen";
import RegistrationEmailValidation from "./RegistrationEmailValidation";
import RegistrationEmailEndOfFLow from "./RegistrationEmailEndOfFlow";
import RegistrationEmailInvalidToken from "./RegistrationEmailInvalidToken.js";
import { getCountry } from "../../shared/CountryUtils.js";

/**
 * -2 = no state - is used while waiting to see whether token supplied is valid or invalid
 * -1 = invalid token step
 * 0 = email validation step
 * 1 = email was sent step
 * 2 = client registration 
 * 3 = branch registration
 * 4 = users registration
 * 5 = model upload
 * 6 = finish
 */
const LAST_ACTIVE_STEP = 6;
const BACKEND_REGISTRATION_STEPS_TO_FRONTEND_REGISTRATION_STEPS = {
  "EmailValidation": 0,
  "ClientCreation": 2,
  "UserCreation": 4,
  "ModelUpload": 5
};

const RegistrationClientDialog = () => {
  const { setLoading, setTextHeader, setTextMessage, setProgress } = useLoading();
  const [searchParams, setSearchParams] = useSearchParams();
  const [registrationEmailValidationMetadata, setRegistrationEmailValidationMetadata] = useState(undefined);
  const [activeStep, setActiveStep] = useState(searchParams.get("token") ? -2 : 0);
  const [clientName, setClientName] = useState("");
  const [branchName, setBranchName] = useState("");
  const defaultCountry = useMemo(() => {
    const defaultCountry = getCountry();
    return defaultCountry ? defaultCountry[0] : null;
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");
    async function checkToken() {
      try {
        const response = await fetch(`/Registration/client/validateToken?token=${encodeURIComponent(token)}`, {
          method: "GET",
        });
        
        if (response.ok) {
          const data = await response.json();
          data.token = token;
          setRegistrationEmailValidationMetadata(data);
          setClientName(data.companyName);
          setActiveStep(BACKEND_REGISTRATION_STEPS_TO_FRONTEND_REGISTRATION_STEPS[data.step]);
        } else if (response.status == 401)
          setActiveStep(-1);
        else
          throw new Error(response.statusText);
      } catch (error) {
        console.error(error);
        setTextHeader("A network error happened");
        setTextMessage("Something is wrong");
    
        setLoading(true);
        setProgress(0);
      }
    }
    if (token) {
      checkToken();
    }
  }, []);

  const handleNext = () => {
    if (activeStep === LAST_ACTIVE_STEP || activeStep == 1)
      window.location.href = process.env.REACT_APP_TWINZO_MAIN_DOMAIN.trim();
    else
      setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevStep) => prevStep + 1);
  }

  const handleCancel = () => {
    // use the fetch fro DeleteCLient endpoint with the clientLogin value
    fetch(`/Registration/clients`, {
      method: "DELETE",
      headers: {
          "Content-Type": "application/json",
      },
    }).then(response => {
        if (!response.ok) {
            console.error(response.status, response.body, response);
        }
    }).finally(() => {
      window.location.replace(activeStep === 0 ? process.env.REACT_APP_TWINZO_MAIN_DOMAIN.trim() : "/");
    });
  };

  return (
    // Dialog registration wrapper is a functional component that takes children as a prop and returns an HTML element
    <div className="dialog-container d-flex justify-content-center align-items-center flex-column">
      <div className="row">
        <div>
          {activeStep + 1 > 0 && (
          <div className="d-flex justify-content-end">
            {/* Paging element */}
            <div className="paging">{activeStep + 1}/{LAST_ACTIVE_STEP + 1}</div>
          </div>)}
          <div className="dialog position-relative">
            <div className="dialog-border">
              {/* Nice left border with radius and gradient */}
            </div>
            <div className="">
              {activeStep === 0 && (
                <RegistrationEmailValidation
                  onNext={handleNext}
                  onCancel={handleCancel}
                  showCancel={true}
                  showPrevious={false}
                  showNext={true}
                  showSkip={false}
                  nextButtonText="Register"
                  defaultCountry={defaultCountry}
                />
              )}
              {activeStep === 1 && (
                <RegistrationEmailEndOfFLow
                  onNext={handleNext}
                  onCancel={handleCancel}
                  onSkip={handleSkip}
                  showCancel={false}
                  showPrevious={false}
                  showNext={true}
                  showSkip={false}
                  nextButtonText="Ok"
                />
              )}
              {activeStep === 2 && (
                <RegistrationClientForm
                  clientName={clientName}
                  setClientName={setClientName}
                  onNext={handleNext}
                  onCancel={handleCancel}
                  showCancel={true}
                  showPrevious={false}
                  showNext={true}
                  showSkip={false}
                />
              )}
              {activeStep === 3 && (
                <RegistrationBranchForm
                  clientName={clientName}
                  branchName={branchName}
                  setBranchName={setBranchName}
                  onNext={handleNext}
                  onCancel={handleCancel}
                  registrationEmailValidationMetadata={registrationEmailValidationMetadata}
                  showCancel={true}
                  showPrevious={false}
                  showNext={true}
                  showSkip={false}
                />
              )}
              {activeStep === 4 && (
                <RegistrationUserForm
                  onNext={handleNext}
                  onCancel={handleCancel}
                  registrationEmailValidationMetadata={registrationEmailValidationMetadata}
                  showCancel={true}
                  showPrevious={false}
                  showNext={true}
                  showSkip={false}
                />
              )}
              {activeStep === 5 && (
                <RegistrationModelUploadForm
                  onNext={handleNext}
                  onCancel={handleCancel}
                  showCancel={false}
                  showPrevious={false}
                  showNext={true}
                  showSkip={true}
                />
              )}
              {activeStep === 6 && (
                <RegistrationFinalScreenForm
                  onNext={handleNext}
                  showCancel={false}
                  showPrevious={false}
                  showNext={true}
                  showSkip={false}
                  nextButtonText="Finish"
                />
              )}
              {activeStep === -1 && (
                <RegistrationEmailInvalidToken
                  onNext={handleNext}
                  showCancel={false}
                  showPrevious={false}
                  showNext={false}
                  showSkip={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationClientDialog;
