import React from "react";
import RegistrationForm from "./RegistrationForm.js";

const RegistrationEmailInvalidToken = ({ onNext, onCancel, onSkip, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {

  return (
    <RegistrationForm
      onNext={onNext}
      onSkip={onSkip}
      onCancel={onCancel}
      isInputValid={true}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Invalid token!</h4>
        <div className="dialogContent">
          <p>
            Your token has expired. 
          </p>
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationEmailInvalidToken;
