import React, { useState, useEffect, useMemo } from "react";
import RegistrationForm from "./RegistrationForm.js";
import { useLoading } from "../../components/LoadingProvider.js";
import Select from 'react-select';
import { useTimezoneSelect, allTimezones } from "react-timezone-select"
import { reactSelectStyles, reactSelectLabel } from "../../shared/Constants.js";

const labelStyle = "original";

const RegistrationBranchForm = ({
  onNext,
  onCancel,
  registrationEmailValidationMetadata,
  clientName,
  branchName,
  setBranchName,
  showCancel, showPrevious, showNext, showSkip, nextButtonText
}) => {
  const {
    setLoading,
    setTextHeader,
    setTextMessage,
  } = useLoading();
  const [errorMessage, setErrorMessage] = useState("");
  const [isInputValid, setIsInputValid] = useState(false);
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, allTimezones })
  const [selectedTimezone, setSelectedTimezone] = useState(
    parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  );
  const TimezoneReactSelectLabel = useMemo(() => reactSelectLabel("Timezone", "Timezone"), [])

  useEffect(() => {
    // if textbox is filled correctly and setSelectedTimezone is defined
    setIsInputValid(validateInput(branchName) && selectedTimezone !== "");
  }, [branchName, selectedTimezone]);

  const handleNext = async () => {
    // check if input is filled corectly and reCapcha is verified
    // if (!isInputValid || !isCaptchaVerified) {
    if (!isInputValid) {
      setErrorMessage("Please fill in all the required fields.");
      return;
    }

    // set loading text
    setTextHeader("Creating digital twin space...");
    setTextMessage(
      "Setting up your digital twin and configuring spatial datasets..."
    );

    setLoading(true);

    // Send client registration request to the controller on the server
    fetch("/Registration/client", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ClientName: clientName,
        BranchName: branchName,
        Timezone: selectedTimezone.value,
        RegistrationValidationToken: registrationEmailValidationMetadata.token
      }),
    })
      .then((response) => {
        if (response.ok) {
          onNext();
        } else {
          setErrorMessage("Client registration failed.");
        }
        return response.json();
      })
      .catch((error) => {
        setErrorMessage("Something went wrong.");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateInput = (inputValue) => {
    const isValid = /^[a-zA-Z0-9]{3,50}$/.test(inputValue);
    return isValid;
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      isInputValid={isInputValid}
      showCancel={showCancel}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Type in the name of your digital twin</h4>
        <div className="dialogContent">
          <p>
            Let's give your digital twin a name! Please enter a name for your
            digital twin that will help you easily identify it in the future.
            Also, please provide the timezone for your digital twin so that we
            can ensure accurate data tracking.
          </p>
          <div className="form-group">
            <div className="form-floating mt-3">
              <input
                name="BranchName"
                className={!isInputValid ? "form-control invalid-input" : "form-control"}
                type="text"
                required
                placeholder=""
                onChange={(e) => setBranchName(e.target.value)}
              />
              <label htmlFor="BranchName">Name of the digital twin …</label>
            </div>
            <Select id="Timezone" className={"form-floating w-100 mt-3"} required placeholder="Timezone"  //controlShouldRenderValue={false}
            options={options} value={selectedTimezone} onChange={setSelectedTimezone} menuPlacement="top" styles={reactSelectStyles} components={{ Control: TimezoneReactSelectLabel }} />
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationBranchForm;
