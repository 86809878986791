import React, { useState } from 'react';
//import { ReactMultiEmail } from 'react-multi-email';
import { isEmail } from 'react-multi-email';

import RegistrationForm from './RegistrationForm.js';
import { useLoading } from '../../components/LoadingProvider.js';
import TagBox from '../../components/TagBox.js';
// import TimezoneSelect from 'react-timezone-select';

// RegistrationUserForm is set of fields to register a users
// Input field is text field allowing list of email addresses to be entered
// Input field email separator is comma
// Email addresses are sent an invitation to become administrators of client workspace
const RegistrationUserForm = ({ onNext, onCancel, registrationEmailValidationMetadata, showCancel, showPrevious, showNext, showSkip, nextButtonText }) => {
  const { setLoading, setTextHeader, setTextMessage } = useLoading();
  const [errorMessage, setErrorMessage] = useState('');
  const [emails, setEmails] = useState(registrationEmailValidationMetadata ? [{value: registrationEmailValidationMetadata.emailAddress, notRemovable: true}] : []);
  //const [isEmailValid, setIsEmailValid] = useState(false);
  const [isInputValid, setIsInputValid] = useState(registrationEmailValidationMetadata ? true : false);


  const handleNext = async () => {
    if (isInputValid) {
      setTextHeader("Inviting users...")
      setTextMessage("Sending invitation emails and assigning permissions...");

      setLoading(true);
      fetch('/Registration/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({emails: emails.map(email => email.value), RegistrationValidationToken: registrationEmailValidationMetadata.token}),
      })
        .then(response => {
          if (response.ok) {
            onNext();
          } else {
            setErrorMessage('Something went wrong. Please press "Cancel" and try again later.');
            setIsInputValid(false);
          }
          return response.json();
        })
        .catch(error => {
          setErrorMessage('Something went wrong. Please press "Cancel" and try again later.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <RegistrationForm
      onNext={handleNext}
      onSkip={onNext}
      onCancel={onCancel}
      showCancel={showCancel}
      isInputValid={isInputValid}
      showPrevious={showPrevious}
      showNext={showNext}
      showSkip={showSkip}
      nextButtonText={nextButtonText}
    >
      <>
        <h4>Invite your colleagues</h4>
        <div className="dialogContent">
          <p>
            Great job! Now it's time to invite administrators to your workspace.
            Please enter the email addresses of the people you want to invite,
            including yourself, and they will receive an invitation to become administrators.
            The maximum number of invited users is 5.
          </p>
          <p className="mt-3">
            Insert email addresses
          </p>
          <div className="form-group">
            <TagBox id='EmailAddress' label='Email addresses' minTagCount={1} maxTagCount={5} tags={emails} setTags={setEmails} setIsValid={setIsInputValid} inputType="email"
            tagValidation={isEmail}/>
          </div>
          {errorMessage && <p className={"error-message mt-3"}>{errorMessage}</p>}
        </div>
      </>
    </RegistrationForm>
  );
};

export default RegistrationUserForm;