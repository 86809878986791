import { useState, useRef, useEffect } from 'react';
import HorizontalScroll  from "./HorizontalScroll";

function TagBox({ className = "", minTagCount = 0, maxTagCount = 1000, id="", label="", inputType="text", tags, setTags, setIsValid, tagValidation }) {
    const inputElement = useRef(null);
    const horizontalMenuRef = useRef(null);
    const [isValidInternal, setIsValidInternal] = useState(isValid());

    useEffect(() => {
        const valid = isValid();
        setIsValidInternal(valid);
        setIsValid(valid);
    }, [tags]);

    function isValid() {
        return tags.length >= minTagCount && tags.length <= maxTagCount;
    }

    return (
        <HorizontalScroll horizontalScrollContainerRef={horizontalMenuRef}>
        <div className='position-relative'>
            <div className={"tagBox horizontalScroll form-floating " + className + (isValidInternal ? "" : " invalid-input")} onClick={(e) => { 
                if (!e.target.classList.contains("btn-close-small")) { 
                    inputElement.current.focus();
                    inputElement.current.scrollIntoView();
                } }}
            onMouseDown={(e) => { e.preventDefault(); }} ref={horizontalMenuRef}>
                {tags.map((tag, index) => (
                    <span className="tag leading-trim" key={tag.value + index}>{tag.value}
                    {tag.notRemovable ?? 
                        <button className="btn btn-close-small leading-trim-x-height" type='button' onClick={(e) => {
                            setTags((previousTags) => previousTags.filter(t => t !== tag))
                            }} aria-label='Remove'><span className="closeSign">&times;</span></button>}
                    </span>
                ))}
                <input type={inputType} className={`form-control${tags.length ? "" : " w-100"}${tags.length == maxTagCount ? " w-0 px-0" : ""}`} placeholder="" id={id}
                ref={inputElement}
                disabled={tags.length == maxTagCount}
                onKeyDown={(e) => {
                    if (e.key == "Enter") {
                        e.preventDefault();
                        const newTag = e.target.value.trim();

                        if (newTag && !tags.find(tag => tag.value === newTag)) {
                            const isTagValid = tagValidation ? tagValidation(newTag) : true;
                            if (isTagValid) {
                                setTags((oldTags) => [...oldTags, { value: newTag}]);
                                e.target.value = "";
                                e.target.focus();
                            }
                        }
                    } else {
                        inputElement.current.scrollIntoView();
                    }
                }}
                />
                <label htmlFor={id}>{label}</label>
            </div>
        </div>
        </HorizontalScroll>
    );
}

export default TagBox;