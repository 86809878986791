import React, { useMemo } from "react";
import Select from 'react-select'
import flags from 'react-phone-number-input/flags'
import { reactSelectLabel, reactSelectStyles } from "../shared/Constants";
import countryList from 'react-select-country-list';

function formatOptionLabel({value, label, code}) {
    const Flag = flags[value];
    return (
        <div style={{ display: "flex", alignItems:"center" }}>
            <Flag width="25px" className="countryFlagIcon" style={{minWidth: "25px", marginTop: "1px"}}/>&nbsp;&nbsp;
            <span>{label}</span>
        </div>
    );
}

function TwinzoCountrySelect({id="", required, value=null, setValue, className="", label, menuPlacement}) {
    const countryOptions = useMemo(() => countryList().getData(), []);
    const SelectLabel = useMemo(() => reactSelectLabel(id, label), []);
        
    return (
        <Select id={id} className={className} required={required} placeholder=""
        options={countryOptions} value={value} onChange={setValue} menuPlacement={menuPlacement} styles={reactSelectStyles} components={{ Control: SelectLabel }}
        formatOptionLabel={formatOptionLabel}/>
    )
};

export default TwinzoCountrySelect;
